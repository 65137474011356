import {
  useEffect, useState, useCallback, useRef,
} from 'react'
import cx from 'classnames'
import { of as of$ } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Icon as LegacyIcon, withObservables } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'
import { useRouter, useLocationQuery, useLocation } from '@/router'
import Icon from '@/shared/icon'
import { isEmpty } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { CorePages as PAGES } from '@/utils/consts'
import DigitalTwinSideNav from '@/containers/Twin/Graph/LeftBar/floatingBar'
import classes from '../index.module.css'
import OperateSideNav from './operate'

const enhanceData = withObservables([], () => ({
  defaultTree: dbProvider.observeSettings('PhysicalTwinDefaultTree'),
  featureFlags: dbProvider.observeFlags([ 'StreamJobs' ]),
}))

const enhanceTwinData = withObservables([ 'defaultTree' ], ({ defaultTree }) => ({
  twinsChain: defaultTree ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', defaultTree))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      switchMap(([ item ]) => (item ? item.observeTwinsChain : of$([]))),
      map(items => items.reverse()),
    ) : of$([]),
}))

const routeFinder = (route) => {
  if (route.children?.length) {
    return routeFinder(route.children[0])
  }

  return route.path
}

const SideNav = ({
  list, width, onChangeWidth, isCollapsed, twinsChain, featureFlags,
}) => {
  const [ isExpanded, setExpanded ] = useState() // this is for menu item

  const refLeftBar = useRef()

  const auth = useAuth()
  const router = useRouter()
  const loc = useLocation()
  const query = useLocationQuery()
  const intl = useIntl()
  const [ ,, corePage, currentPage ] = loc.pathname.split('/')

  const handleClick = (route, hasView, active) => {
    if (active) {
      if (route.hasView && query.view === 'table') {
        router.push({ name: route.path, query: {} })
      }
      // return null
    }

    if (route.children) {
      if (isExpanded === route.attr.id) {
        setExpanded(undefined)
      } else {
        setExpanded(route.attr.id)
      }
    } else if (hasView) {
      if (route.route.tree) {
        router.push({ query: { ...query, view: 'table' } })
      } else {
        console.log('1')
      }
    } else if (!route.children && !active) {
      router.push({ name: route.path, params: route.params })
    }

    return null
  }

  const checkIsActivePageWithChildren = useCallback((item) => {
    if (!item.children) {
      return false
    }
    const hasChildrenRoute = item.children.find(({ route }) => route?.name === currentPage)

    return !!hasChildrenRoute
  }, [ currentPage ])

  const checkPermission = item => !!(
    isEmpty(item.access) ||
      item.access.every(rule => auth.checkAccessRules(rule[1], rule[0]))
  )

  useEffect(() => {
    if (!currentPage && list?.length) {
      if (list?.length) {
        const path = routeFinder(list[0])
        router.push({ name: path })
      }
    }
  }, [ currentPage, list, router ])

  const showItems = width // && !isCollapsed

  if (!Object.values(PAGES).includes(corePage)) {
    return (
      <div
        className={cx('position-relative')}
        style={{ width: 0 }}
      />
    )
  }

  return (
    <div
      className={cx('position-relative py-2', classes.sideNav)}
      onMouseEnter={() => isCollapsed && onChangeWidth(true)}
      onMouseLeave={() => {
        if (isCollapsed) {
          onChangeWidth(false)
        }
      }}
    >
      {showItems && corePage === PAGES.TWIN ? (
        <DigitalTwinSideNav
          ref={refLeftBar}
          container="#content-container"
          blockIds={[]}
          // setEditBlockId={setEditBlockId}
          // linkMode={linkMode}
          // setLinkMode={setLinkMode}
          // onClickCreate={onClickCreate}
          rootTwin={twinsChain[twinsChain.length - 1]}
        />
      ) : null}

      {showItems && corePage === PAGES.OPERATE ? <OperateSideNav /> : null}
      {showItems && [ PAGES.DESIGN, PAGES.TWIN ].includes(corePage) ? list?.map((item, idx) => {
        if (!featureFlags.StreamJobs && item.attr.id === 'jobs') {
          return null
        }
        const hasChildren = item.children?.length
        const children = hasChildren && item.children.filter(checkPermission)
        const hasAccess = !hasChildren && checkPermission(item)
        if ((hasChildren && !children?.length) || (!hasChildren && !hasAccess)) {
          return null
        }
        const active = [ item.route?.name, item.attr?.id ].includes(currentPage) || checkIsActivePageWithChildren(item)

        return (
          <div
            key={item.id || idx}
          >
            <div
              className={cx(
                'btn btn-secondary-alt py-2 ps-3 d-flex justify-content-start',
                classes.menuItem,
                { active },
              )}
              onClick={() => handleClick(item, null, active)}
              aria-hidden
            >
              <LegacyIcon name={item.icon || 'placeholder'} className="me-2" size="md" />
              <span className="fill-flex text-start text-truncate">{intl.t(item.name)}</span>
              {hasChildren ? (
                <Icon name="faAngleDown" className="ms-auto" />
              ) : null}
              {item.hasView && [ item.route?.name, item.attr?.id ].includes(currentPage) ? (
                <button
                  type="button"
                  className="btn btn-fill-secondary-alt btn-text ms-auto"
                  title={intl.t('t/menu.description.openList')}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClick(item, item.hasView)
                  }}
                >
                  <Icon name="faTableList" />
                </button>
              ) : null}
            </div>
            {hasChildren && (isExpanded === item.attr.id || checkIsActivePageWithChildren(item)) ? (
              <div className={cx(
                'd-flex flex-column',
                { [classes.child]: active },
              )}
              >
                {children.map((child, idx) => (
                  <div
                    key={child.id || idx}
                    className={cx(
                      'btn btn-secondary-alt py-2 ps-3 justify-content-start',
                      classes.menuItem,
                      { active: currentPage === child.route?.name },
                    )}
                    onClick={() => handleClick(child)}
                    aria-hidden
                  >
                    <span className="ms-4 text-truncate">{intl.t(child.name)}</span>
                  </div>
                )) }
              </div>
            ) :
              null}
          </div>
        )
      }) : null}
      <div
        className={cx('btn btn-fill-secondary-alt', classes.collapseBtn)}
        onClick={() => {
          if (width && isCollapsed) {
            onChangeWidth(true, true)
          } else {
            onChangeWidth(false, true)
          }
        }}
        aria-hidden
      >
        <Icon name={isCollapsed ? 'faAngleRight' : 'faAngleLeft'} />
      </div>
    </div>
  )
}

export default enhanceData(enhanceTwinData(SideNav))
